.Login {
    position: absolute;
    width: 400px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.MarginTop16 {
    margin-top: 16px;
}