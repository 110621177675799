.Alert {
    border-radius: 10px;
    border: 1px solid rgb(85, 85, 85);
    position: relative;
    color: rgb(85, 85, 85);
    background-color: #fff;
    margin-bottom: 16px;
    font-size: .875rem;
    line-height: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    padding-right: 2.25rem;

    & strong {
        margin-right: .5rem;
        text-transform: uppercase;
    }

    & button {
        font-size: 1.5rem;
        line-height: 1.5;
        margin-top: .5rem;
        margin-right: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
        outline: none;
        background-image: none;
        text-transform: none;
        cursor: pointer;
        color: inherit;
    }

    &.error {
        color: rgb(220, 53, 69);
        border-color: rgb(220, 53, 69);
    }

    &.success {
        color: rgb(40, 167, 69);
        border-color: rgb(40, 167, 69);
    }

    &.info {
        color: rgb(20, 83, 136);
        border-color: rgb(20, 83, 136);
    }
}