.Activation {
    width: 100%;

    & .container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & .form {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: stretch;
            gap: 1rem;

            &GroupDown {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    & .result {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;

        & > * {
            width: 50%;
        }
    }
}