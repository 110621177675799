.BillingUser {
    font-size: 16px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 16px;

    & > * {
       width: 50%;
    }
}