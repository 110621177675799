.Requests {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 16px;

    & .grid {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        flex-wrap: wrap;
        gap: 16px;

        & > * {
            width: calc(50% - 16px) !important;

            &.w100 {
                width: 100% !important;
            }
        }
    }

    & .result {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;

        & > * {
            width: 50%;
        }
    }
}