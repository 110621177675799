.Layout {
    display: flex;
    min-height: 100vh;
    width: 100%;
    position: relative;
}

.Navigation {
    width: 130px;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.16);
}

.Content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 130px);
    margin-left: 130px;
    padding: 16px 24px;
}

.ContentFullWidth {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
}

@media print {
    .Navigation {
        display: none;
    }

    .Content {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}