
.PrintRequest {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 12px;
    margin-bottom: 50px;

    &.PrintRequestNew {
        font-size: 10px;

        & .logo {
            width: 150px;
            height: 150px;
            left: 0;
            top: 0;
            right: auto;
        }
    }

    & .mt100 {
        margin-top: 100px;
    }

    & .header {
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: center;
        width: 200px;
    }

    & .logo {
        display: block;
        right: 10px;
        top: 10px;
        position: absolute;
        width: 100px;
        height: 100px;
        object-fit: contain;

    }
    
    & h4 {
        text-align: center;
    }

    & .line {
        border-bottom: 1px solid #000;
        display: inline-block;
        width: 100%;
        min-height: 14px;
    }

    & .block {
        display: flex;
        flex-wrap: nowrap;
        padding: 5px 0;

        & strong {
            min-width: fit-content;
            margin-right: 5px;
            margin-left: 5px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    & .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 20px;

        & > * {
            display: block;
        }
    }

    & .center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    & .sign {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        margin-top: 25px;

        & > * {
            width: 30%;
            display: flex;
            flex-direction: column;
        }
    }

    & .mt20 {
        margin-top: 20px;
    }

    & table {
        border: 1px solid #000;
        border-left: none;
        border-bottom: none;
        margin-bottom: 20px;

        & td, & th {
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            font-size: 10px;
        }

        & td {
            padding: 5px;
            font-size: 10px;
        }
    }
}