.Textarea {
    display: flex;
    flex-direction: column;
    width: 100%;

    & label {
        font-weight: 700;
        font-size: .875rem;
        line-height: 1.25rem;
        margin-bottom: .5rem;
    }

    & textarea {
        box-sizing: border-box;
        appearance:none;
        background-color:rgba(255,255,255,1);
        border:1px solid rgba(221,221,221,1);
        border-radius:.25rem;
        padding: .5rem 1rem;
        color:rgba(85,85,85,1);
        width:100%;
        font-size: 100%;

        &:focus {
            border-color:rgba(20,83,136,1);
            outline:2px solid transparent;
            outline-offset:2px;
            box-shadow:0 0 #0000,0 0 #0000,0 0 #0000;
        }
    }

    & small {
        margin-top: .5rem;
        font-size: .75rem;
        line-height: 1rem;
        color: rgba(136,136,136,1);
    }

    &.error {
        color: rgb(220, 53, 69);

        & input {
            border: 1px solid rgb(220, 53, 69);
        }

        & small {
            color: rgb(220, 53, 69);
        }
    }
}