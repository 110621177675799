.Nav {
    display: flex;
    flex-direction: column;

    & .link {
        text-decoration: none;
        display: block;
        padding: 1.5rem 1.25rem;
        position: relative;
        text-align: center;
        color: rgba(85,85,85,1);
        transition-property: color;
        transition-timing-function: linear;
        transition-duration: .2s;
        height: auto;
        border-bottom: 1px solid rgba(221,221,221,1);

        &:hover {
            color: rgba(20,83,136,1);
        }

        &.active {
            color: rgba(20,83,136,1);
            font-weight: 500;
        }

        & .icon {
            display: inline-block;
            font-size: 2rem;
            line-height: 1;
        }

        & .title {
            font-size: .875rem;
            display: block;
            margin-top: .25rem;
            opacity: 1;
            transition-property: all;
            transition-timing-function: linear;
            transition-duration: .2s;
        }
    }
}