.Dashboard {
  width: 100%;

  & .container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;

      & > * {
        width: 50%;
      }

      & .col25 {
        width: 25%;
      }

      & .col33 {
        width: 33.3%;
      }

      & .col66 {
        width: 66.6%;
      }

      & .col75 {
        width: 75%;
      }

      & .col100 {
        width: 100%;
      }
    }
  }
}