.CallPopup {
    background-color: rgba(255,255,255,0.9);
    border-radius: 10px;
    box-shadow: 0 0 #0000,0 0 #0000,1px 1px 5px 0 rgba(0, 0, 0, 0.16);
    min-width: 400px;
    position: fixed;
    right: 16px;
    bottom: 16px;

    & .header {
        border-color: rgb(238,238,238);
        border-bottom-width: 1px;
        display: flex;
        align-items: center;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        & h5 {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;

            & i {
                font-weight: bold;
                margin-right: 5px;
            }
        }

        & button {
            position: absolute;
            font-size: 1.5rem;
            line-height: 1.5;
            margin-right: .5rem;
            top: 0;
            right: 0;
            background-color: transparent;
            border: none;
            outline: none;
            background-image: none;
            text-transform: none;
            cursor: pointer;
            color: inherit;
        }
    }

    & .body {
        padding: 1.25rem;
    }
}