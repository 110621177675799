.KeyValueRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid rgba(221,221,221,1);
    padding: 10px 0;

    & .title {
        font-weight: bold;
        width: 50%;
    }

    & .value {
        width: 50%;
    }
}