.Onu {
    .link {
        border-color: transparent;
        border-radius: 9999px;
        border-width: 1px;
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        padding: .5rem 1.25rem;
        color: rgba(255,255,255,1);
        transition-property: all;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration: 150ms;
        transition-timing-function: linear;
        transition-duration: .2s;
        cursor: pointer;
        background-color: rgba(85,85,85,1);
        text-decoration: none;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 16px;

        &:hover {
            background-color: rgba(47, 47, 47, 1);
            color: rgba(255,255,255,1);
        }

        &.active {
            background-color: rgba(20,83,136,1);
        }
    }
}