.Zte {
    display: flex;
    flex-direction: column;

    & .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;

        & > * {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        & .col25 {
            width: 25%;
        }

        & .col33 {
            width: 33.3%;
        }

        & .col66 {
            width: 66.6%;
        }

        & .col75 {
            width: 75%;
        }
    }

    & .group {
        display: flex;
        flex-direction: column;
        margin-bottom: .75rem;
    }

    .mb10 {
        margin-bottom: 10px;
    }

    .my10 {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}