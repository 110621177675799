.Statistics {
    & .grid {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        flex-wrap: wrap;
        gap: 16px;

        & > * {
            width: calc(50% - 16px) !important;

            &.maxH700 {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 16px;
                justify-content: stretch;
                max-height: 750px;
                overflow-y: auto;
                padding-bottom: 8px;
            }
        }
    }
}