.Customer {
    width: 100%;

    & .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & .row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 16px;

            & > * {
                width: 50%;
            }
        }

        & .col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 16px;

            & > * {
                width: 50%;
            }
        }

        & .form {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: stretch;
            gap: 1rem;

            &GroupDown {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    & .result {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;

        & > * {
            width: 50%;
        }
    }
}